// PermissionsContext.tsx
import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PermissionsService } from "../../services/permissions/PermissionsService";
import { UserPermissions } from "../../model/members/UserPermissions";

interface PermissionsContextType {
    permissions: UserPermissions;
    refreshPermissions: () => Promise<void>;
    isLoading: boolean;
}

const PermissionsContext = createContext<PermissionsContextType | undefined>(undefined);

const defaultPermissions: UserPermissions = {
    isLoading: true,
    member: {
        name: '',
        username: '',
        dateOfBirth: new Date(),
        emailAddress: '',
        addressLine1: '',
        addressLine2: '',
        townCity: '',
        countyState: '',
        postZipCode: '',
        phoneNumber: '',
        membershipId: '',
        specialOffers: false,
        status: 'Unknown',
    },
    authUser: {
        emailAddress: '',
        auth0Id: '',
        roles: [],
        permissions: [],
        clients: [],
    },
    permissions: [],
};

export const PermissionsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
    const [permissions, setPermissions] = useState<UserPermissions>(defaultPermissions);
    const [isLoading, setIsLoading] = useState(true);

    const fetchPermissions = async () => {
        if (!isAuthenticated) {
            setPermissions(prev => ({ ...prev, isLoading: false }));
            setIsLoading(false);
            return;
        }

        try {
            const permissionsService = new PermissionsService(
                getAccessTokenSilently,
                localStorage.getItem("currentClientName") ?? null
            );

            const userPermissions = await permissionsService.getUserPermissions();
            setPermissions({
                ...userPermissions,
                isLoading: false
            });
        } catch (error) {
            setPermissions(defaultPermissions);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPermissions();
    }, [isAuthenticated, user?.sub]);

    const refreshPermissions = async () => {
        setIsLoading(true);
        await fetchPermissions();
    };

    return (
        <PermissionsContext.Provider value={{ permissions, refreshPermissions, isLoading }}>
            {children}
        </PermissionsContext.Provider>
    );
};

// Custom hook to use permissions
export const usePermissions = () => {
    const context = useContext(PermissionsContext);
    if (context === undefined) {
        throw new Error('usePermissions must be used within a PermissionsProvider');
    }
    return context;
};

// Auto-refresh timer hook (optional)
export const usePermissionsAutoRefresh = (refreshInterval: number = 300000) => {
    const { refreshPermissions } = usePermissions();

    useEffect(() => {
        const interval = setInterval(refreshPermissions, refreshInterval);
        return () => clearInterval(interval);
    }, [refreshPermissions, refreshInterval]);
};