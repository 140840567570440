import {Modal, Tag, Typography} from 'antd';
import React, {useState} from 'react';
import {Slot} from "../../model/bookings/Slot";
import MakeBooking from "./MakeBooking";
import {Booking} from "../../model/bookings/Booking";

interface AvailableSlotCardProps {
    slot: Slot;
    booking: Booking | null;
    facilityName: string;
    bookingIntervalMins: number;
}

function createDefaultBooking(court: string, startTime: Date): Booking {
    const endTime = new Date(startTime);
    endTime.setMinutes(endTime.getMinutes() + 60);

    return {
        bookingDateTimeUtc: startTime,
        bookingEndTimeUtc: endTime,
        facilityName: court,
        bookingStatus: 'Available',
        paymentTransactionStatus: 'Unpaid',
        memberName: '',
        membershipId: '',
        playingMembers: []
        // Add other properties as needed, with appropriate default values
    };
}

const AvailableSlotCard: React.FC<AvailableSlotCardProps> = ({ slot, booking, facilityName, bookingIntervalMins}) => {
    booking = booking || createDefaultBooking(facilityName, slot.startTime);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleOpenModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };
    const startTime = new Date(slot.startTime);

    return (
        <>
            <Tag onClick={handleOpenModal} /* ... */ >
                <Typography.Text><strong>Available</strong></Typography.Text>
            </Tag>
            <Modal title="Available Slot Details" open={isModalVisible} onCancel={handleCloseModal} footer={null}>
                <MakeBooking bookingIntervalMins={bookingIntervalMins} slot={slot} booking={booking} facilityName={facilityName} onClose={handleCloseModal} />
            </Modal>
        </>
    );
};
export default AvailableSlotCard;