import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';
import { AllPermissions } from "../../model/auth/Permissions";
import { usePermissions } from "../permissions/PermissionsContext";
type PermissionType = typeof AllPermissions;
type PermissionKeys = keyof PermissionType;
type PermissionValues = PermissionType[PermissionKeys];

interface ProtectedRouteProps {
    children: React.ReactNode;
    requiredPermission?: PermissionValues;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
                                                                  children,
                                                                  requiredPermission
                                                              }) => {
    const { isAuthenticated, isLoading: authLoading } = useAuth0();
    const { isLoading: permissionsLoading, permissions } = usePermissions();
    const location = useLocation();

    console.log('Checking permissions for endpoint', location.pathname,
        'with required permission', requiredPermission,
        'current permissions', permissions);

    if (authLoading || permissionsLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Spin size="large" />
            </div>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // Check if the user has the required permission
    if (requiredPermission && !permissions?.permissions.some(p => p.name === requiredPermission)) {
        console.log('Permission denied:', requiredPermission);
        return <Navigate to="/unauthorized" replace />;
    }

    return <>{children}</>;
};