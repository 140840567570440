import {ClubBookingApiClient} from "../../common/api/ClubBookingApiClient";
import { CreatePointOfSaleTransactionRequest } from "../../model/pointofsale/CreatePointOfSaleTransactionRequest";
import { PointOfSaleItem } from "../../model/pointofsale/PointOfSaleItem";

export class PointOfSaleService extends ClubBookingApiClient {
    async getPosItems(): Promise<PointOfSaleItem[]> {
        console.log('Fetching point of sale items');
        return this.get<PointOfSaleItem[]>('/pos/items');
    }

    async makePayment(request: CreatePointOfSaleTransactionRequest): Promise<void> {
        return this.post('/pos/transaction', request);
    }
}