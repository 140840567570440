import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Space, Typography, Tag, Flex } from 'antd';
import { usePointOfSaleService } from '../../../services/pointofsale/PointOfSaleServiceProvider';
import { Booking } from '../../../model/bookings/Booking';
import QuantitySelector from '../../common/QuantitySelector';
import { PointOfSaleTransactionItem } from '../../../model/pointofsale/PointOfSaleTransactionItem';
import { CreatePointOfSaleTransactionRequest } from '../../../model/pointofsale/CreatePointOfSaleTransactionRequest';
import { Slot } from '../../../model/bookings/Slot';
import { PointOfSaleBookingSlot } from '../../../model/pointofsale/PointOfSaleBookingSlot';

interface CheckInBookingProps {
  bookingIntervalMins: number // TODO: Revise this as we should be able to get this from the facility itself
  booking: Booking;
  slot: Slot;
  isVisible: boolean;
  onClose: (wasSuccessful?: boolean) => void; 
  facilityPrice: number;
}


interface BasketItem extends PointOfSaleTransactionItem {
  total: number;
}

const CheckInBooking: React.FC<CheckInBookingProps> = ({
  bookingIntervalMins,
  booking,
  slot,
  isVisible,
  onClose,
  facilityPrice
}) => {

  function calculateFacilityPrice(): number {
    const numberOfSlots = (booking.bookingEndTimeUtc.getTime() - booking.bookingDateTimeUtc.getTime()) / (bookingIntervalMins * 60 * 1000);
    return numberOfSlots * facilityPrice;
  }
  
  const posService = usePointOfSaleService();
  const [items, setItems] = useState<PointOfSaleTransactionItem[]>([]);
  const [basket, setBasket] = useState<BasketItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(calculateFacilityPrice);
  const [totalFacilityPrice, setTotalFacilityPrice] = useState(calculateFacilityPrice);

  const loadPosItems = async () => {
    setIsLoading(true);
    try {
      const availableItems = await posService.getPosItems();
      setItems(availableItems.map(item => ({ item: item, quantity: 0 }))); // TODO: Add quantity to the item
    } catch (error) {
      console.error('Error loading POS items:', error);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (isVisible) {
      loadPosItems();
    }
  }, [isVisible, posService]);

  

  const handleQuantityChange = (itemId: string, quantity: number) => {
    // First update the items state to show correct quantities in the table
    setItems(prevItems => prevItems.map(item => 
      item.item.id === itemId ? { ...item, quantity } : item
    ));
  
    // Now update the basket
    const item = items.find(i => i.item.id === itemId);
    if (!item) return;
  
    const updatedBasket = [...basket];
    const basketItemIndex = updatedBasket.findIndex(i => i.item.id === itemId);
    
  
    if (quantity > 0) {
      const basketItem: BasketItem = {
        ...item,
        quantity,
        total: item.item.price * quantity
      };
  
      if (basketItemIndex >= 0) {
        updatedBasket[basketItemIndex] = basketItem;
      } else {
        updatedBasket.push(basketItem);
      }
    } else if (basketItemIndex >= 0) {
      updatedBasket.splice(basketItemIndex, 1);
    }
  
    setBasket(updatedBasket);
    calculateTotal(updatedBasket);
  };

  const calculateTotal = (currentBasket: BasketItem[]) => {
    const itemsTotal = currentBasket.reduce((sum, item) => sum + item.total, 0);
    setTotal(itemsTotal + totalFacilityPrice);
  };

  const handleProcessPayment = async () => {
    try {
      const numberOfSlots = (slot.endTime.getTime() - slot.startTime.getTime()) / (bookingIntervalMins * 60 * 1000);
      const totalFacilityPrice = numberOfSlots * facilityPrice;
      
      
      const pointOfSaleBookingSlot: PointOfSaleBookingSlot = {
        bookingSlot: slot,
        price: totalFacilityPrice
      };
      const request: CreatePointOfSaleTransactionRequest = {
        membershipId: booking.membershipId,
        items: basket,
        bookingSlots: [pointOfSaleBookingSlot],
        amount: total
      };
      await posService.makePayment(request);
      onClose(true);
    } catch (error) {
      console.error('Error marking as paid:', error);
      onClose(false);
    }
  };

  const handleMarkAsUnpaid = async () => {
    try {
      console.log('Marking as unpaid ', booking.bookingDateTimeUtc);
      // await paymentService.markAsUnpaid(booking.id);
      onClose(true);
    } catch (error) {
      console.error('Error marking as unpaid:', error);
      onClose(false);
    }
  };

  // Updated columns definition in CheckInBooking.tsx
  const columns = [
    {
      title: 'Item',
      dataIndex: ['item', 'name'],
      key: 'name',
    },
    {
      title: 'Price',
      dataIndex: ['item', 'price'],
      key: 'price',
      render: (price: number) => `£${price?.toFixed(2) ?? 0}`,
    },
    {
      title: 'Type',
      dataIndex: ['item', 'type'],
      key: 'type',
    },
    {
      title: 'Quantity',
      key: 'quantity',
      render: (value: number, record: PointOfSaleTransactionItem) => (
        <QuantitySelector
          value={record.quantity}
          onChange={(value) => handleQuantityChange(record.item.id, value)}
        />
      ),
    },
  ];

  return (
    <Modal
      title="Check In Booking"
      open={isVisible}
      onCancel={() => onClose(true)}
      width={800}
      footer={null}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Typography.Title level={5}>Booking Details</Typography.Title>
        <Space>
          <Tag color="blue">{booking.facilityName}</Tag>
          <Tag color="green">{new Date(booking.bookingDateTimeUtc).toLocaleString('en-GB')}</Tag>
          <Tag color="purple">{booking.memberName}</Tag>
        </Space>

        <Typography.Title level={5}>Additional Items</Typography.Title>
        <Table
          dataSource={items}
          columns={columns}
          rowKey="id"
          pagination={false}
          loading={isLoading}
        />

        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Title level={5}>Summary</Typography.Title>
          <div>Facility Cost: £{totalFacilityPrice.toFixed(2)}</div>
          {basket.map(item => (
            <div key={item.item.id}>
              {item.item.name}: £{item.total.toFixed(2)} ({item.quantity} x £{item.item.price.toFixed(2)})
            </div>
          ))}
          <Typography.Title level={4}>Total: £{total.toFixed(2)}</Typography.Title>
        </Space>

        <Flex justify="center" gap="middle">
          <Button type="primary" onClick={handleProcessPayment}>
            Process Payment
          </Button>
          <Button type="primary" danger onClick={handleMarkAsUnpaid}>
            Mark as Unpaid
          </Button>
          <Button onClick={() => onClose(true)}>
            Cancel
          </Button>
        </Flex>
      </Space>
    </Modal>
  );
};

export default CheckInBooking;