// QuantitySelector.tsx
import React from 'react';
import { Button, Typography, Space } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

interface QuantitySelectorProps {
  value: number;
  onChange: (value: number) => void;
}

const QuantitySelector: React.FC<QuantitySelectorProps> = ({ value, onChange }) => {
  const handleIncrement = () => {
    onChange(value + 1);
  };

  const handleDecrement = () => {
    if (value > 0) {
      onChange(value - 1);
    }
  };

  return (
    <Space>
      <Button
        icon={<MinusOutlined />}
        onClick={handleDecrement}
        disabled={value === 0}
        size="small"
      />
      <Typography.Text style={{ margin: '0 8px', minWidth: '20px', textAlign: 'center' }}>
        {value}
      </Typography.Text>
      <Button
        icon={<PlusOutlined />}
        onClick={handleIncrement}
        size="small"
      />
    </Space>
  );
};

export default QuantitySelector;