import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    CircularProgress,
    Box,
    useMediaQuery,
    useTheme, Dialog, Button,
    Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { BookingScheduleTable } from './BookingScheduleTable';
import { useBookingService } from "../../../services/bookings/BookingServiceProvider";
import { FacilityScheduleForDay } from "../../../model/bookings/FacilityScheduleForDay";
import {CompactWeekCalendar} from "../../common/CompactCalendar";
import { format } from 'date-fns';

const FACILITY_TYPES: string[] = ['Squash', 'Padel', 'Tennis']; // TODO: get from backend

export const FacilityBookingSchedule: React.FC = () => {
    const bookingService = useBookingService();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [selectedFacilityType, setSelectedFacilityType] = useState<string>(FACILITY_TYPES[0]);
    const [currentFacilityIndex, setCurrentFacilityIndex] = useState(0);
    const [scheduleData, setScheduleData] = useState<FacilityScheduleForDay[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const browserLocale = new Intl.Locale(navigator.language);
    const isUK = browserLocale.baseName.startsWith('en-GB');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleDateChange = (date: Date) => {
        setSelectedDate(date);
        // No need to manually sync the week view as it will happen via useEffect
    };

    const handleToday = () => {
        const today = new Date();
        setSelectedDate(today);
        setCalendarOpen(false);
    };

    const fetchScheduleData = async () => {
        setIsLoading(true);
        try {
            const nextDay = new Date(selectedDate);
            nextDay.setDate(selectedDate.getDate() + 1);
            const data = await bookingService.getBookingsForFacilityType(
                selectedDate,
                nextDay,
                selectedFacilityType
            );
            setScheduleData(data);
        } catch (error) {
            console.error('Error fetching schedule data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleBookingSuccess = () => {
        fetchScheduleData();
    };

    useEffect(() => {
        fetchScheduleData();
    }, [selectedDate, selectedFacilityType]);

    const handleFacilityTypeChange = (event: SelectChangeEvent) => {
        setSelectedFacilityType(event.target.value);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Card>
                <CardContent>
                    {isMobile ? (
                        <>
                            <Box sx={{ mb: 2 }}>
                                <FormControl fullWidth>
                                    <InputLabel>Facility Type</InputLabel>
                                    <Select
                                        value={selectedFacilityType}
                                        onChange={handleFacilityTypeChange}
                                        label="Facility Type"
                                        disabled={isLoading}
                                    >
                                        {FACILITY_TYPES.map((type) => (
                                            <MenuItem key={type} value={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            <CompactWeekCalendar
                                selectedDate={selectedDate}
                                onChange={handleDateChange}
                                onCalendarOpen={() => setCalendarOpen(true)}
                            />

                            <Dialog
                                open={calendarOpen}
                                onClose={() => setCalendarOpen(false)}
                                fullWidth
                                maxWidth="xs"
                            >
                                <Box sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    <DateCalendar
                                        value={selectedDate}
                                        onChange={(newDate) => {
                                            if (newDate) {
                                                handleDateChange(newDate);
                                                setCalendarOpen(false);
                                            }
                                        }}
                                        showDaysOutsideCurrentMonth={false}
                                        disablePast
                                        views={['day']}
                                        sx={{
                                            width: '100%',
                                            '& .MuiPickersDay-root': {
                                                borderRadius: '8px',
                                            },
                                            '& .MuiPickersDay-root.Mui-selected': {
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                '&:hover': {
                                                    backgroundColor: theme.palette.primary.dark,
                                                },
                                            },
                                        }}
                                    />
                                    <Button
                                        onClick={handleToday}
                                        variant="outlined"
                                        sx={{ mt: 1, mb: 1 }}
                                    >
                                        Today
                                    </Button>
                                </Box>
                            </Dialog>
                        </>
                    ): (
                        <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                            <DatePicker
                                label="Select Date"
                                value={selectedDate}
                                onChange={(newDate) => newDate && setSelectedDate(newDate)}
                                disabled={isLoading}
                                sx={{ width: 240 }}
                                format={isUK ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                            />
                            <FormControl sx={{ width: 200 }}>
                                <InputLabel>Facility Type</InputLabel>
                                <Select
                                    value={selectedFacilityType}
                                    onChange={handleFacilityTypeChange}
                                    label="Facility Type"
                                    disabled={isLoading}
                                >
                                    {FACILITY_TYPES.map((type) => (
                                        <MenuItem key={type} value={type}>
                                            {type}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Typography variant="h6" sx={{ textAlign: 'center', mt: 2 }}>
                                {format(selectedDate, 'EEEE dd MMMM, yyyy')}                        
                            </Typography>
                        </Box>
                    )}

                    {isLoading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                            <CircularProgress />
                        </Box>
                    ) : scheduleData.length > 0 ? (
                        <BookingScheduleTable
                            schedules={scheduleData}
                            onBookingSuccess={handleBookingSuccess}
                            selectedDate={selectedDate}
                            initialFacilityIndex={currentFacilityIndex}
                            onFacilityChange={setCurrentFacilityIndex}
                        />
                    ) : (
                        <Box sx={{ textAlign: 'center', py: 3 }}>
                            No schedule data available
                        </Box>
                    )}
                </CardContent>
            </Card>
        </LocalizationProvider>
    );
};
