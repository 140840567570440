import React from 'react';
import { Box } from '@mui/material';
import { Slot } from "../../../model/bookings/Slot";
import { Booking } from "../../../model/bookings/Booking";

interface BookingCellProps {
    slot: Slot;
    booking: Booking | null;
    facilityName: string;
    bookingIntervalMins: number;
    onCellClick: (booking: Booking | null, slot: Slot, facilityName: string) => void;
}

export const BookingCell: React.FC<BookingCellProps> = ({
    slot,
    booking,
    bookingIntervalMins,
    facilityName,
    onCellClick,
}) => {
    // Check if this is the first slot of a booking
    const isFirstSlotOfBooking = booking && 
        booking.bookingDateTimeUtc.getTime() === slot.startTime.getTime();
    
    // Calculate how many intervals this booking spans
    const getBookingSpan = () => {
        if (!booking) return 1;
        const durationMins = 
            (booking.bookingEndTimeUtc.getTime() - booking.bookingDateTimeUtc.getTime()) / (1000 * 60);
        return Math.ceil(durationMins / bookingIntervalMins);
    };

    const getBookingColor = (booking: Booking | null) => {
        if (!booking) {
            return {
                base: '#c8e6c9',    // Light green for available slots
                hover: '#a5d6a7'    // Darker green on hover
            };
        }
    
        if (booking.paymentTransactionStatus === 'Completed') {
            return {
                base: '#81c784',    // Darker green for completed payments
                hover: '#66bb6a'    // Even darker green on hover
            };
        }
    
        return {
            base: '#bbdefb',    // Light blue for incomplete payments
            hover: '#90caf9'    // Darker blue on hover
        };
    };

    // Only show booking content on the first slot
    const shouldShowContent = !booking || isFirstSlotOfBooking;

    // Calculate height based on booking span
    const height = booking ? `${getBookingSpan() * 40}px` : '40px'; // Adjust base height (40px) as needed
    const colors = getBookingColor(booking);


    return (
        <Box
            onClick={() => onCellClick(booking, slot, facilityName)}
            sx={{
                height,
                width: '100%',
                cursor: 'pointer',
                border: '1px solid rgba(224, 224, 224, 1)',
                borderRadius: '4px',
                transition: 'background-color 0.2s',
                display: shouldShowContent ? 'flex' : 'none',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px',
                backgroundColor: colors.base,
                '&:hover': {
                    backgroundColor: colors.hover,
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                },
                position: 'relative',
                overflow: 'hidden'
            }}
        >
            {shouldShowContent && (
                <>
                    {booking ? (
                        <>
                            <Box sx={{ 
                                fontWeight: 'bold',
                                fontSize: '0.9rem',
                                textAlign: 'center'
                            }}>
                                {booking.memberName}
                            </Box>
                            <Box sx={{ 
                                fontSize: '0.8rem',
                                color: 'text.secondary',
                                textAlign: 'center'
                            }}>
                                {new Date(booking.bookingDateTimeUtc).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false
                                })}
                                {' - '}
                                {new Date(booking.bookingEndTimeUtc).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false
                                })}
                            </Box>
                        </>
                    ) : (
                        <Box sx={{ 
                            fontSize: '0.9rem',
                            textAlign: 'center'
                        }}>
                            Available
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};