import React, {useMemo, useState} from "react";
import {Button, Dropdown, Layout, Menu, MenuProps} from "antd";
import {
    CalendarOutlined,
    HomeOutlined,
    MenuOutlined,
    MoneyCollectFilled,
    UserAddOutlined,
} from "@ant-design/icons";
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import {BookingServiceProvider} from "./services/bookings/BookingServiceProvider";
import {MemberServiceProvider} from "./services/members/MemberServiceProvider";
import {PaymentServiceProvider} from "./services/payments/PaymentServiceProvider";
import {withAuthenticationRequired, useAuth0} from "@auth0/auth0-react";
import Auth0ProviderWithConfig from "./components/login/Auth0ProviderWithConfig";
import DirectDebitManagement from "./components/directdebit/DirectDebitManagement";
import DirectDebitRunManager from "./components/directdebit/DirectDebitRunManager";
import MemberProfileScreen from "./components/membership/MemberProfileScreen";
import {FacilityBookingSchedule} from "./components/bookings/facility/FacilityBookingSchedule";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {ClientProvider} from "./common/ClientProvider";
import {ClientSelector} from "./common/ClientSelector";
import HomePage from "./HomePage";
import {ClientServiceProvider} from "./services/clients/ClientServiceProvider";
import NewMemberRegistration from "./components/membership/NewMemberRegistration";
import {PermissionsServiceProvider} from "./services/permissions/PermissionsServiceProvider";
import {UnauthorizedPage} from "./components/common/UnauthorizedPage";
import {ProtectedRoute} from "./common/api/ProtectedRoute";
import {AdminPermissions, MemberPermissions, PaymentPermissions} from "./model/auth/Permissions";
import MemberProfileScreenV2 from "./components/membership/MemberProfileScreenV2";
import { PermissionsProvider, usePermissions } from "./common/permissions/PermissionsContext";
import { PointOfSaleServiceProvider } from "./services/pointofsale/PointOfSaleServiceProvider";

const {Header, Sider, Content} = Layout;

const LayoutWithRouter: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const {logout} = useAuth0();
    const screens = useBreakpoint();
    const isMobile = !screens.lg;
    const { isLoading: permissionsLoading, permissions } = usePermissions();

    const handleLogout = () => {
        logout({logoutParams: {returnTo: window.location.origin}});
    };

    const baseMenuItems: MenuProps['items'] = [
        {
            key: "/home",
            icon: <HomeOutlined/>,
            label: <Link to="/home">Home</Link>,
        },
        {
            key: "/bookings/facilities",
            icon: <CalendarOutlined/>,
            label: <Link to="/bookings/facilities">Bookings</Link>,
        },
        {
            key: "/newmember",
            icon: <UserAddOutlined/>,
            label: <Link to="/newmember">New Member</Link>,
        }
    ];

// Function to check admin permissions
    const hasAdminPermission = () => {
        if (permissionsLoading || !permissions) return false;
        return permissions.permissions.some(p =>
            p.name === AdminPermissions.ClubAdmin ||
            p.name === AdminPermissions.FortiusAdmin
        );
    };

// Generate full menu items based on permissions
    const menuItems = useMemo(() => {
        const items: MenuProps['items'] = [...(baseMenuItems || [])];

        if (hasAdminPermission()) {
            // Add members menu
            items.push({
                key: "members",
                icon: <UserAddOutlined/>,
                label: "Members",
                children: [
                    {
                        key: "/profiles",
                        icon: <UserAddOutlined/>,
                        label: <Link to="/profiles">Manage Members</Link>,
                    },
                ],
            });

            // Add direct debit menu
            items.push({
                key: "directdebits",
                icon: <MoneyCollectFilled/>,
                label: "Direct Debit",
                children: [
                    {
                        key: "/directdebit",
                        icon: <MoneyCollectFilled/>,
                        label: <Link to="/directdebit">Member Direct Debits</Link>,
                    },
                    {
                        key: "/directdebit/runs",
                        icon: <MoneyCollectFilled/>,
                        label: <Link to="/directdebit/runs">Direct Debit Runs</Link>,
                    },
                ],
            });
        }

        // Add logout at the end
        items.push({
            key: "logout",
            label: "Logout",
            onClick: handleLogout
        });

        return items;
    }, [permissions, permissionsLoading, handleLogout]); // Added handleLogout to dependencies

    const Logo: React.FC<{ collapsed?: boolean }> = ({collapsed}) => {
        const screens = useBreakpoint();
        const isMobile = !screens.lg;

        return (
            <div
                style={{
                    padding: isMobile ? "8px 0" : "16px",
                    textAlign: "center",
                    transition: "all 0.2s",
                    height: isMobile ? "64px" : "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <img
                    src="/ClubLogo.jpg"
                    alt="Club Logo"
                    style={{
                        height: isMobile ? "48px" : collapsed ? "32px" : "64px",
                        width: "auto",
                        objectFit: "contain",
                        maxWidth: "100%",
                        transition: "all 0.2s",
                    }}
                />
            </div>
        );
    };

    const MobileMenu: React.FC = () => {
        const dropdownMenu = (
            <Menu items={menuItems} selectedKeys={[location.pathname]}/>
        );

        return (
            <Dropdown
                overlay={dropdownMenu}
                trigger={["click"]}
                overlayStyle={{
                    maxHeight: "80vh",
                    overflow: "auto",
                }}
            >
                <Button
                    type="text"
                    icon={<MenuOutlined/>}
                    style={{
                        color: "white",
                        fontSize: "20px",
                        padding: "8px 12px",
                    }}
                />
            </Dropdown>
        );
    };

    return (
        <Layout style={{minHeight: "100vh"}}>
            {/* Desktop Sider - Hidden on mobile */}
            {!isMobile && (
                <Sider
                    collapsible
                    collapsed={collapsed}
                    onCollapse={setCollapsed}
                    style={{background: "#00005a"}}
                    width={250}
                >
                    <Logo collapsed={collapsed}/>
                    <Menu
                        theme="dark"
                        mode="inline"
                        selectedKeys={[location.pathname]}
                        style={{
                            background: "#00005a",
                            borderRight: 0,
                            color: "white",
                        }}
                        items={menuItems}
                    />
                </Sider>
            )}

            <Layout>
                <Header
                    style={{
                        padding: "0 16px",
                        background: "#00005a",
                        display: "flex",
                        alignItems: "center",
                        height: "64px",
                        position: "sticky",
                        top: 0,
                        zIndex: 1000,
                    }}
                >
                    {isMobile ? (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                height: "64px",
                            }}
                        >
                            <MobileMenu/>
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    justifyContent: "center",
                                    overflow: "hidden",
                                }}
                            >
                                <Logo/>
                            </div>
                        </div>
                    ) : (
                        // Add this block for desktop view
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <div/>
                            {/* Empty div for spacing */}
                            <ClientSelector/>
                            <div style={{paddingRight: "16px"}}/>
                        </div>
                    )}
                </Header>

                <Content
                    style={{
                        margin: isMobile ? "16px 8px" : "24px 16px",
                        padding: isMobile ? 16 : 24,
                        minHeight: 280,
                    }}
                >
                    <PermissionsServiceProvider>
                        <ClientServiceProvider>
                            <ClientServiceProvider>
                                <BookingServiceProvider>
                                    <MemberServiceProvider>
                                        <PointOfSaleServiceProvider>
                                        <PaymentServiceProvider>
                                            <Routes>
                                                <Route path="/home" element={
                                                    <ProtectedRoute>
                                                        <HomePage/>
                                                    </ProtectedRoute>
                                                }/>
                                                <Route path="/bookings/facilities" element={
                                                    <ProtectedRoute>
                                                        <FacilityBookingSchedule/>
                                                    </ProtectedRoute>
                                                }/>
                                                <Route path="/profiles" element={
                                                    <ProtectedRoute requiredPermission={AdminPermissions.ClubAdmin}>
                                                        <MemberProfileScreen />
                                                    </ProtectedRoute>
                                                }/>
                                                <Route path="/newmember" element={
                                                    <ProtectedRoute requiredPermission={MemberPermissions.SubmitNewMemberForm}>
                                                        <NewMemberRegistration/>
                                                    </ProtectedRoute>
                                                }/>
                                                <Route path="/directdebit" element={
                                                    <ProtectedRoute requiredPermission={AdminPermissions.ClubAdmin}>
                                                        <DirectDebitManagement/>
                                                    </ProtectedRoute>
                                                }/>
                                                <Route path="/directdebit/runs" element={
                                                    <ProtectedRoute requiredPermission={AdminPermissions.ClubAdmin}>
                                                        <DirectDebitRunManager/>
                                                    </ProtectedRoute>
                                                }/>
                                                <Route path="/unauthorized" element={<UnauthorizedPage/>}/>
                                            </Routes>
                                        </PaymentServiceProvider>
                                        </PointOfSaleServiceProvider>
                                    </MemberServiceProvider>
                                </BookingServiceProvider>
                            </ClientServiceProvider>
                        </ClientServiceProvider>
                    </PermissionsServiceProvider>
                </Content>
            </Layout>
        </Layout>
    );
};

const ProtectedLayout = withAuthenticationRequired(LayoutWithRouter);

const Auth0ErrorHandler: React.FC = () => {
    const {error} = useAuth0();

    if (error) {
        return (
            <div>
                <h1>An error occurred</h1>
                <p>{error.message}</p>
            </div>
        );
    }

    return null;
};

const styles = `
        .ant-menu-sub.ant-menu-inline {
            background: #161B38 !important;
        }
        .ant-menu-item-selected {
            background-color: #2A305C !important;
        }
     `;

const App: React.FC = () => {
    return (
        <>
            <style>{styles}</style>
            <Auth0ProviderWithConfig>
                <ClientProvider>
                  <PermissionsProvider>
                    <Router>
                        <Auth0ErrorHandler/>
                        <ProtectedLayout/>
                    </Router>
                    </PermissionsProvider>
                </ClientProvider>
            </Auth0ProviderWithConfig>
        </>
    );
};

export default App;
