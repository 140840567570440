import React from 'react';
import {PointOfSaleService} from "./PointOfSaleService";
import {useClient} from "../../common/ClientProvider";
import {useAuth0} from "@auth0/auth0-react";

interface PointOfSaleServiceProviderProps {
    children: React.ReactNode;  // Add this line
}

// Create a context for the booking service
export const PointOfSaleServiceContext = React.createContext<PointOfSaleService | undefined>(undefined);

// Create a provider component for the booking service
export const PointOfSaleServiceProvider: React.FC<PointOfSaleServiceProviderProps> = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { currentClientId } = useClient();

    const service = React.useMemo(() => {
        return new PointOfSaleService(getAccessTokenSilently, currentClientId);
    }, [getAccessTokenSilently, currentClientId]);

    return (
        <PointOfSaleServiceContext.Provider value={service}>
            {children}
        </PointOfSaleServiceContext.Provider>
    );
};

// Create a hook to use the PointOfSale service
export const usePointOfSaleService = (): PointOfSaleService => {
    const context = React.useContext(PointOfSaleServiceContext);
    if (context === undefined) {
        throw new Error('usePointOfSaleService must be used within a PointOfSaleServiceProvider');
    }
    return context;
};